<template>
    <div>
        <el-dialog title="温馨提示" :visible.sync="visible" center @close="cancelFollow">
            <div style="text-align:center">
                此客户有
                <font style="color: #FE8900;">线索</font>
                还未跟进，线索超时将导至客户流入公海
            </div>
            <div style="text-align:center;margin-top:10px">是否立即跟进线索？</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="followClue">跟进线索</el-button>
                <el-button @click="cancelFollow">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: this.show,
            clueId: '',
        };
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        show() {
            this.visible = this.show;
        },
    },
    methods: {
        init(id) {
            this.clueId = id;
        },
        cancelFollow() {
            this.$emit('update:show', false);
        },
        // 跟进线索
        followClue() {
            let data = {
                id: this.clueId,
            };
            this.$emit('followCluePage', data);
            this.visible = false;
        },
    },
};
</script>

<style></style>
